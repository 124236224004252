/* latin-ext */
@font-face {
  font-family: "Economica";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: local("Economica Bold Italic"), local("Economica-BoldItalic"), url("../../assets/fonts/economica/Economica-BoldItalic.ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Economica";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: local("Economica Bold Italic"), local("Economica-BoldItalic"), url("../../assets/fonts/economica/Economica-BoldItalic.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Economica";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: local("Economica Bold Italic"), local("Economica-BoldItalic"), url("../../assets/fonts/economica/Economica-BoldItalic.ttf") format("ttf");
}
/* latin-ext */
@font-face {
  font-family: "Economica";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("Economica"), local("Economica-Regular"), url("../../assets/fonts/economica/Economica-Regular.ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Economica";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("Economica"), local("Economica-Regular"), url("../../assets/fonts/economica/Economica-Regular.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Economica";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("Economica"), local("Economica-Regular"), url("../../assets/fonts/economica/Economica-Regular.ext.ttf") format("ttf");
}
/* latin-ext */
@font-face {
  font-family: "Economica";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Economica Bold"), local("Economica-Bold"), url("../../assets/fonts/economica/Economica-Bold.ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Economica";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Economica Bold"), local("Economica-Bold"), url("../../assets/fonts/economica/Economica-Bold.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Economica";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Economica Bold"), local("Economica-Bold"), url("../../assets/fonts/economica/Economica-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "hot-icons";
  src: url("../../assets/fonts/hot-icons/hot-icons.ttf?9ik2my") format("truetype"), url("../../assets/fonts/hot-icons/hot-icons.woff?9ik2my") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
html,
body {
  --mdc-typography-font-family: "Economica", sans-serif;
  font-family: "Economica", sans-serif;
  display: flex;
  position: fixed;
  flex-flow: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  color: #000;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-style: inherit;
}

mwc-dialog {
  --mdc-theme-surface: #000;
  --hot-button-color: #f1d06b;
  --mdc-dialog-heading-ink-color: #fff;
  --hot-quantity-color: #fff;
  --mdc-ripple-color: #fff;
}

.payments-list {
  min-height: 175px;
}

hot-icon-button:not([resolved]) {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  font-size: 24px;
}
hot-icon-button:not([resolved])[size=large] {
  font-size: 40px;
}