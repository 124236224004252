hot-icon-button {
    &:not([resolved]) {
        display: inline-block;
        position: relative;
        width: 1em;
        height: 1em;
        font-size: 24px;

        &[size='large'] {
            font-size: 40px;
        }
    }
}
