@use './fonts/index' as fonts;
@use './colors';

html,
body {
    --mdc-typography-font-family: 'Economica', sans-serif;

    @include fonts.economica;

    display: flex;
    position: fixed;
    flex-flow: column;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overscroll-behavior: none;
    color: colors.$primary;
}
