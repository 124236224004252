/* latin-ext */
@font-face {
    font-family: 'Economica';
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: local('Economica Bold Italic'), local('Economica-BoldItalic'), url('../../assets/fonts/economica/Economica-BoldItalic.ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Economica';
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: local('Economica Bold Italic'), local('Economica-BoldItalic'), url('../../assets/fonts/economica/Economica-BoldItalic.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Economica';
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: local('Economica Bold Italic'), local('Economica-BoldItalic'), url('../../assets/fonts/economica/Economica-BoldItalic.ttf') format('ttf');
}

/* latin-ext */
@font-face {
    font-family: 'Economica';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local('Economica'), local('Economica-Regular'), url('../../assets/fonts/economica/Economica-Regular.ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Economica';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local('Economica'), local('Economica-Regular'), url('../../assets/fonts/economica/Economica-Regular.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Economica';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local('Economica'), local('Economica-Regular'), url('../../assets/fonts/economica/Economica-Regular.ext.ttf') format('ttf');
}

/* latin-ext */
@font-face {
    font-family: 'Economica';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: local('Economica Bold'), local('Economica-Bold'), url('../../assets/fonts/economica/Economica-Bold.ext.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Economica';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: local('Economica Bold'), local('Economica-Bold'), url('../../assets/fonts/economica/Economica-Bold.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Economica';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: local('Economica Bold'), local('Economica-Bold'), url('../../assets/fonts/economica/Economica-Bold.ttf') format('ttf');
}
